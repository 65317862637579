
  /**
   * Note: This file is autogenerated. Do not make any manual changes to this file 
   */

  export const allowanceType = ["Limited","Unlimited"] as const
      
  export const appointmentEventEnum = ["Created","CreatedThroughMessageDoctorConversion","StatusUpdated","ConvertedToMessageDoctor","Booked","BookingCancelled"] as const
      
  export const appointmentHoldEventEnum = ["HoldCompleted","NoPaymentRequired","HoldReleased","BookingCreated","BookingCancelled","HoldExpired","HoldReaped"] as const
      
  export const appointmentHoldStatusEnum = ["Created","HoldCompleted","HoldFailed","HoldReleased","HoldExpired","BookingCreated","BookingCancelled","HoldDiscarded"] as const
      
  export const appointmentStatusEnum = ["NotAvailable","Available","OnHold","Booked","Completed","Nonworking","Cancelled"] as const
      
  export const bookingCancellationReasonEnum = ["NoLongerRelevant","UnableToAttend","BookedInError","Rebooked","Other"] as const
      
  export const bookingEventEnum = ["BookingCreated","AppointmentSwap","CancelBooking","BookingUpdated","ConsultationSessionStarted","ConsultationSessionCompleted","ConsultationCompleted","FollowUpActionsCompleted"] as const
      
  export const bookingReservationEnum = ["Agent","Online"] as const
      
  export const bookingStatusEnum = ["PendingConsultation","ConsultationInProgress","ConsultationCompleted","PendingFollowUpActions","Completed","Cancelled"] as const
      
  export const callTicketDocumentStatusType = ["NotRequired","Required","Received"] as const
      
  export const callTicketStatusEnum = ["AppointmentBooking","WaitingForAdastraInput","RequiresRebooking","Completed","FurtherActionRequired","CancelledByAdmin","OnlineCancelled","PendingManualIntervention","InProgress","TechnicalError","PendingFollowUpActions","CancelledByGP","AllocationRequired","NoShow"] as const
      
  export const clientEventTypeEnum = ["UserCreated","BookingCreated","BookingUpdated","BookingCancelled","UpcomingConsultationReminder","ConsultationReminder","ConsultationIsReady","ConsultationCompleted","ConsultationOutputCreated","BookingAttachmentCreated","PrescriptionReady","ConsultationOutputsCreated","ConsultationRefundRequested"] as const
      
  export const clientValidationTypeEnum = ["ClientIdentifier","Multipoint","ClientFunction"] as const
      
  export const clinicalPractitionerAttachmentTypeEnum = ["ClinicalPractitionerDocument","ClinicalPractitionerESignature","ClinicalPractitionerPhoto"] as const
      
  export const clinicalServiceDeliveryType = ["Synchronous","Asynchronous"] as const
      
  export const consultationEventEnum = ["BookingCancelled","ClinicalPractitionerConnected","PatientConnected","ClinicalPractitionerDisconnected","PatientDisconnected","ClinicalPractitionerPing","PatientPing","ClinicalPractitionerCameraOn","ClinicalPractitionerCameraOff","PatientCameraOn","PatientCameraOff","ClinicalPractitionerSoundOn","ClinicalPractitionerSoundOff","PatientSoundOn","PatientSoundOff","PatientAddedBookingAttachment","ChatMessage","RecordingStarted","RecordingEnded","ClinicalPractitionerClosedSession","ClinicalPractitionerRestartedSession","ClinicalPractitionerCompletedConsultation","FollowUpActionCompleted","AllFollowUpActionsCompleted"] as const
      
  export const consultationFollowUpActionEnum = ["ConsultationReport","Prescription","FitNote","MedicalCertificate","SpecialistReferral","ConsultationNotesToGP","SecondMedicalOpinion","ConsultationNotesToPatient","IrelandMedicalCertificate","IrelandEmergencyDepartmentReferral","AdditionalPatientFollowUp"] as const
      
  export const consultationOutputEnum = ["ConsultationReport","Prescription","FitNote","MedicalCertificate","SpecialistReferral","SecondMedicalOpinionReferral","IrelandEmergencyDepartmentReferral","TreatmentDocument","TreatmentPlan"] as const
      
  export const consultationOutputResourceType = ["Unknown","File","Link"] as const
      
  export const consultationOutputTagEnum = ["Action","Education","Reference"] as const
      
  export const consultationStatusEnum = ["Pending","ClinicalPractitionerWaiting","PatientWaiting","InProgress","Reconnecting","SessionClosed","PendingConsultationCompletion","PendingFollowUpActions","CompletedWithoutFollowUpActions","CompletedWithFollowUpActions","BookingCancelled"] as const
      
  export const consultationTypeEnum = ["Phone","Video","MessageDoctor","Chat","Onsite"] as const
      
  export const dateTimeIntervalComparisonResultEnum = ["Equal","Later","Earlier"] as const
      
  export const extraCompliance = ["Low","Medium","High"] as const
      
  export const fileUsageEnum = ["Unknown","ConsultationRecording","BookingAttachment","PatientDocument","ConsultationOutput","MessageAttachments","PatientInternalNotesAttachments","ClinicalPractitionerAttachments"] as const
      
  export const genderEnum = ["Unknown","Male","Female","Other"] as const
      
  export const identityVerificationCheck = ["DocumentAuthenticity","Liveness","FaceMatch","TextExtraction"] as const
      
  export const identityVerificationSessionStatusEnum = ["Created","InProgress","Processing","Verified","Failed","Expired"] as const
      
  export const identityVerificationStatusEnum = ["NotStarted","Started","InProgress","Processing","Verified","Failed","Expired","Blocked"] as const
      
  export const identityVerificationTaskState = ["Unknown","Done"] as const
      
  export const identityVerificationTaskType = ["Unknown","TextExtraction"] as const
      
  export const instructionTypesEnum = ["CSO","CP"] as const
      
  export const jobTypeEnum = ["ConsultationReminder"] as const
      
  export const messageDoctorThreadEventEnum = ["PatientMessageReceived","AppointmentAllocated","PatientManagementSystemCaseAllocated","ClinicalPractitionerResponseReceived","ClinicalPractitionerResponseSentToPatient","Closed"] as const
      
  export const messageDoctorThreadStatusEnum = ["Created","PendingAppointmentAllocation","PendingPatientManagementSystemAllocation","PendingResponseFromClinicalPractitioner","ClinicalPractitionerResponseReady","ClinicalPractitionerResponseSent","PendingPatientInteraction","Closed"] as const
      
  export const notificationChannelEnum = ["Email","SMS","Push"] as const
      
  export const notificationTypeEnum = ["PasswordResetRequest","PasswordResetConfirmation","ConfirmEmail","AccountCreated","BookingCreated","BookingCreatedGP","BookingCancelled","BookingCancelledGP","ConsultationReminder","ConsultationReminderGP","ConsultationAboutToStart","ConsultationFollowUpActionsReady","MultiFactorAuthentication","MessageDoctorCPAnswered","BookingResendJoiningInformation","ConsultationOutputsAttached","CPUserCreated","AdminUserCreated","TreatmentDocumentsReady","PrescriptionReady","UpcomingConsultationReminder","ConsultationNotesReady","FitNoteReady","MedicalCertificateReady","SpecialistReferralReady","EmergencyDepartmentReferralReady","PaymentFailedInsufficientFunds","IdentityVerificationFailed","ImageRequest","IdentityVerificationStarted","IdentityVerificationPassed","ProvideGpDetailsNotification","HomeTestingKitOrderConfirmation"] as const
      
  export const otpCodeType = ["Registration","Authorization"] as const
      
  export const patientManagementSystemEnum = ["Base","Adastra","vNET2"] as const
      
  export const paymentEventEnum = ["PaymentIntentCreated","PaymentIntentUpdated","PaymentIntentCancelled","PaymentAuthorised","PaymentCaptured","PaymentRefunded"] as const
      
  export const productLinkageType = ["ClientIdentifier","Employer"] as const
      
  export const professionalBodies = ["GeneralMedicalCouncil","IrishMedicalCouncil","GeneralPharmaceuticalCouncil","PharmaceuticalSocietyOfIreland","BritishAssociationForCounsellingAndPsychotherapy","IrishAssociationForCounsellingAndPsychotherapy","HealthAndCareProfessionsCouncil","CORU","NursingAndMidwiferyCouncil","NursingAndMidwiferyBoardOfIreland","BritishPsychologicalSociety","PsychologicalSocietyOfIreland","IrishSocietyOfPhysicianAssociates"] as const
      
  export const referralReport = ["NoReferral","GP","EmergencyServices","AncillaryCare"] as const
      
  export const resourceAvailabilityCancellationReasonEnum = ["ShiftCancelled","ReplacedWithFallback"] as const
      
  export const resourceAvailabilityStatusEnum = ["Live","WaitingSwap","Cancelled","ManualEntry","MAD","Nonworking"] as const
      
  export const resourceBookingStatusEnum = ["Live","Cancelled"] as const
      
  export const resourceTypeEnum = ["GP","CallHandler","ClinicalPharmacist","Physiotherapist","Counsellor","Dietitian","SpeechAndLanguage","AdvancedNursePractitioner","Psychologist","Psychiatrist","PhysicianAssociate"] as const
      
  export const restEndpointAuthTypeEnum = ["Basic","ClientCredentials","AccessToken"] as const
      
  export const shiftEventEnum = ["ShiftUpdated","AppointmentsCreated","Deleted","Discarded"] as const
      
  export const shiftSourceEnum = ["Quinyx","Manual"] as const
      
  export const shiftStatusEnum = ["New","Processing","Processed","Discarded"] as const
      
  export const specialistReferral = ["Allergy","Breast","Cardiology","Dental","Dermatology","Endocrinology","Ent","Gastroent","Gynaecology","Haematology","MedicationAdv","Neurology","NonSpecific","Obstetric","Oncology","Orthalmology","Orthopeadics","Other","Paediatrics","Pathology","PostSurgery","PreSurgery","Psychiatry","Radiology","Respiratory","Rheumatology","SecondOpinion","Trauma","TravelVaccs","Urology","URTI","Vascular"] as const
      
  export const specialtiesEnum = ["Unknown","GeneralPractitioner","CallHandler","ClinicalPharmacist","Physiotherapist","Counsellor","Dietitian","SpeechAndLanguage","AdvancedNursePractitioner","Psychologist","Psychiatrist","PhysicianAssociate"] as const
      
  export const stripePaymentMode = ["Single","ConnectDirectCharge","ConnectDestinationCharge"] as const
      
  export const supportedCurrencyEnum = ["None","EUR","GBP","USD"] as const
      
  export const termsTypeEnum = ["ConsentToShare","TermsAndConditions","PrivacyNotice","MedicalTerms"] as const
      
  export const twilioServiceTypeEnum = ["ProgrammableChat","Conversations"] as const
      
  export const userTermsStatusEnum = ["Pending","Accepted"] as const
      
  export const vhiConsultationFileContentType = ["Patient_Document_Image","Consultation_Report","Fit_Note","Medical_Certificate","Referral_Letter"] as const
      